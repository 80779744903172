import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percent',
})
export class PercentPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): unknown {
    if (value == null) {
      return 'n/d';
    }
    if (value.toString() == 'NaN') {
      return 'n/d';
    }
    return (
      value
        .toFixed(2)
        .replace('.', '&')
        .replace('.', '')
        .replace('.', '')
        .replace('.', '')
        .replace('.', '')
        .replace('.', '')
        .replace('&', ',')
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '%'
    );
  }
}
