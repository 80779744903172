import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'dashboard';
  ngOnInit() {
    const nativeLoading = document.getElementById("loadingAngular");
    nativeLoading?.parentNode?.removeChild(nativeLoading);
  }
}
