import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();

  constructor(public authService: OidcSecurityService, public router: Router) {
    this.origin = this.getOrigin();
  }
  origin: string = 'empreenderdinheiro';
  urls = {
    empreenderdinheiro: environment.url.replace('api.', 'investidor.'),
    techfinance: environment.url.replace('api.', ''),
  };

  ngOnInit() {}

  toggleSideBar() {
    this.toggleSideBarForMe.emit();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }
  logout() {
    this.authService.logoff();
  }
  getOrigin(): string {    
    if ((location as any).href.toString().split('investidor').length > 1) {
      return 'empreenderdinheiro';
    }
    return 'techfinance';
  }
  
}