import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'activeTrue',
})
export class ActiveTruePipe implements PipeTransform {
  transform(value: Array<any>, ...args: unknown[]): Array<any> {
    return value.filter((item: any) => item.active == true);
  }
}
