import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'analiseText',
})
export class AnaliseTextPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): string {
    if (value == 1) {
      return 'SUBINDO';
    }
    if (value == 2) {
      return 'CAINDO';
    }
    if (value == 3) {
      return 'ESTÁVEL';
    }
    if (value == 4) {
      return 'INSTÁVEL';
    }
    if (value == 5) {
      return 'FORTE CRESCIMENTO';
    }
    if (value == 6) {
      return 'FORTE QUEDA';
    }
    return '-';
  }
}
