<div class="content">
  <div class="content-heading">
    <div style="margin-bottom: 0px" class="title">Atualização de planilha</div>
    <div class="subtitle">Anexe a planilha para atualizar.</div>
    <form>
      <input
        type="file"
        #fileUpload
        (change)="onUpload($event)"
        name="fileToUpload"
        id="fileToUpload"
      />
      <input (click)="enviar()" type="submit" value="Atualizar" name="submit" />
    </form>
    <div *ngIf="error" class="alert alert-danger" role="alert">
      {{ error.error | json }}
    </div>
  </div>
</div>
