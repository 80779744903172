<ng-template #EdTooltip>
  <div class="tooltip-custom">
    EMPRESAS APROVADAS NOS CRITÉRIOS ED [GOVERNANÇA, TAG ALONG, LIQUIDEZ &
    ENDIVIDAMENTO SAUDÁVEL]
  </div>
</ng-template>
<div class="content">
  <div class="content-heading">
    <div style="margin-bottom: 20px" class="title">
      Busque o nome da empresa ou o ticker e clique para fazer uma análise
    </div>
  </div>
  <div class="content-search">
    <div class="box-search">
      <input [formControl]="searchField" type="text" placeholder="Buscar por ações, empresas" />
      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M3.45847 8.09733C3.45847 4.07792 6.71684 0.819549 10.7362 0.819549C14.7556 0.819549 18.014 4.07792 18.014 8.09733C18.014 12.1167 14.7556 15.3751 10.7362 15.3751C9.05443 15.3751 7.50586 14.8046 6.27348 13.8466L1.37261 18.7475L0.0860684 17.461L4.98694 12.5601C4.02894 11.3277 3.45847 9.77914 3.45847 8.09733ZM10.7364 13.5558C7.72182 13.5558 5.27804 11.112 5.27804 8.09744C5.27804 5.08289 7.72182 2.63911 10.7364 2.63911C13.7509 2.63911 16.1947 5.08289 16.1947 8.09744C16.1947 11.112 13.7509 13.5558 10.7364 13.5558Z"
          fill="black" />
      </svg>
    </div>
  </div>
  <div class="content-filters">
    <span> Setor </span>
    <a class="button-filter" mat-stroked-button [matMenuTriggerFor]="menu">
      {{
        setorField.value == undefined
          ? "Clique para selecionar"
          : setorField.value
      }}
      <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.5 4.60001L0.602887 0.600006L8.39711 0.600007L4.5 4.60001Z" fill="#109CF1" />
      </svg>
    </a>

    <mat-menu #menu="matMenu">
      <button (click)="setorField.setValue(undefined)" mat-menu-item>
        <span>Todos </span>
      </button>
      <button (click)="setorField.setValue(item.setor)" mat-menu-item *ngFor="let item of setores">
        <span>{{ item.setor }} </span>
      </button>
    </mat-menu>

    <span> Porte </span>

    <a class="button-filter" mat-stroked-button [matMenuTriggerFor]="matSmall">
      {{
        porteField.value == undefined
          ? "Clique para selecionar"
          : porteField.value
      }}
      <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.5 4.60001L0.602887 0.600006L8.39711 0.600007L4.5 4.60001Z" fill="#109CF1" />
      </svg>
    </a>

    <mat-menu #matSmall="matMenu">
      <button (click)="porteField.setValue(undefined)" mat-menu-item>
        <span>Todos </span>
      </button>

      <button mat-menu-item (click)="porteField.setValue('LARGE CAP')">
        <span>LARGE CAP </span>
      </button>

      <button mat-menu-item (click)="porteField.setValue('MID CAP')">
        <span>MID CAP </span>
      </button>
      <button mat-menu-item (click)="porteField.setValue('SMALL CAP')">
        <span>SMALL CAP </span>
      </button>
      <button mat-menu-item (click)="porteField.setValue('MICRO CAP')">
        <span>MICRO CAP </span>
      </button>
    </mat-menu>
    <span>
      <mat-checkbox [formControl]="ucField">Exibir empresas fora do universo de cobertura</mat-checkbox>
    </span>
  </div>
  <div class="content-results-info">
    Foram encontradas &nbsp;<b> {{ count }} empresas</b>.
    <div *ngIf="!loading" style="float: right; text-align: right; margin-left: 60px">
      Data da ultima atualização:
      {{ data[0].itens[0].dataUltimaCotacao.replace('.000Z', '') | date: "dd/MM/yyyy" }}
    </div>
  </div>
  <div *ngIf="loading" class="loading">
    <mat-spinner [color]="'accent'" diameter="40" mode="indeterminate">
    </mat-spinner>
  </div>
  <div class="results">
    <div *ngFor="let setor of data" class="setor-list">
      <div class="setor-title">
        {{ setor.setor }}
      </div>
      <div class="row-flex">
        <div *ngFor="let acao of setor.itens" [routerLink]="['/acao/', acao.ticker]" style="cursor: pointer"
          class="item-flex">
          <app-item-action [acao]="acao" [analisados]="analisados"></app-item-action>
        </div>
      </div>
    </div>
  </div>
</div>
<div style="height: 80px"></div>