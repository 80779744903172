<div style="display: block; padding: 24px">
  <canvas
    baseChart
    style="width: 100% !important"
    height="320"
    width="1200"
    [datasets]="lineChartData"
    [labels]="lineChartLabels"
    [options]="lineChartOptions"
    [colors]="lineChartColors"
    [legend]="lineChartLegend"
    [chartType]="lineChartType"
    [plugins]="lineChartPlugins"
  >
  </canvas>
</div>
