import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthFolderService } from '../callback-folder/auth-folder.service';

@Injectable({
  providedIn: 'root',
})
export class ComparativoService {

  constructor(public httpClient: HttpClient, public oidcSecurityService: OidcSecurityService, public authFolderService: AuthFolderService,
  ) {
  }
  get token() {
    if (this.authFolderService.authenticated()) {
      return this.authFolderService.getAccess();
    }
    return this.oidcSecurityService.getAccessToken();
  }
  listAll() {
    return this.httpClient
      .get(
        environment.url + 'main/indexer/comparativo',

        {
          headers: {
            Authorization: 'Bearer ' + this.token,
          },
        }
      )
      .pipe(
        map((item) => {
          return item;
        })
      );
  }
}
