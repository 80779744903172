import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label, BaseChartDirective } from 'ng2-charts';
import { convertDateYearMonth } from '../utils';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit {
  public lineChartData: ChartDataSets[] = [
    { data: [0, 0, 0, 0, 0] },
  ];
  public lineChartLabels: Label[] = [];
  @Input() title: string;
  @Input() field: string = 'value';
  @Input() pre: string = 'R$';

  @Input() pos: string = '';
  @Input('data') set setData(value: any) {
    value = JSON.parse(JSON.stringify(value)).reverse();
    this.lineChartLabels = value.map((item) =>
      convertDateYearMonth(item.title)
    );
    this.lineChartData = [
      {
        data: value.map((item) => {
          if (this.pos == '%') {
            return (item[this.field] * 100).toFixed(2)
          }
          return item[this.field].toFixed(2);
        }), label: this.title
      },
    ];
  }
  public lineChartOptions: ChartOptions = {
    responsive: true,
    spanGaps: true,
    hover: {
      axis: 'x',
      animationDuration: 0,
    },
    animation: {
      duration: 0,
    },
    layout: {},
    scales: {
      yAxes: [
        {
          ticks: {
            maxTicksLimit: 5,
            fontSize: 12,
            beginAtZero: true,
            fontColor: '#000',
            callback: (value: any, index, values) => {
              if (this.pos == '%') {
                //number = number * 100;
              }
              value = value.toLocaleString('pt-BR', {
                minimumIntegerDigits: 1,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
              return this.pre + ' ' + value + ' ' + this.pos;
            },
          },

          gridLines: {
            offsetGridLines: false,
            display: true,
            color: ['grey'],
            borderDash: [2, 4],
          },
        },
      ],
      xAxes: [
        {
          distribution: 'series',
          ticks: {
            fontSize: 12,
            fontColor: '#000',
            autoSkip: true,
            padding: 0,
            maxRotation: 0,
            maxTicksLimit: 8,
            beginAtZero: false,
            callback: function (value, index, values) {
              return value;
            },
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    elements: {
      line: {
        tension: 0,
        fill: true,
      },
      point: {
        radius: 0,
        hoverRadius: 0,
      },
    },
    tooltips: {
      mode: 'x-axis',
      bodyFontColor: '#000',
      backgroundColor: '#FFF',
      bodyFontFamily: 'Roboto',
      titleFontColor: '#109cf1',
      callbacks: {
        label: (i, b) => {
          let number = Number(i.value);
          if (this.pos == '%') {
            //number = number * 100;
          }
          let value = Number(number).toLocaleString('pt-BR', {
            minimumIntegerDigits: 1,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          return this.title + ': ' + this.pre + ' ' + value + ' ' + this.pos;
        },
      },
    },
    legend: {
      align: 'start',
      display: false,
      labels: {
        boxWidth: 12,
      },
    },
  };
  public lineChartColors: Color[] = [
    {
      backgroundColor: 'rgba(16, 156, 241, 0)',
      borderColor: '#109cf1',
      borderWidth: 2,
      pointHoverRadius: 0,
      pointBorderWidth: 5,
      pointBackgroundColor: '#109cf1',
      pointBorderColor: '#109cf1',
      pointHoverBackgroundColor: '#109cf1',
      pointHoverBorderColor: '#109cf1',
      pointHoverBorderWidth: 4,
    },
  ];
  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';
  public lineChartPlugins = [];

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  constructor() { }

  ngOnInit() {
    this.lineChartData[0].label = this.title;
    setTimeout(() => {
      this.lineChartColors[0] = {
        backgroundColor: this.gradient,
        borderColor: '#109cf1',
        borderWidth: 2,
        pointHoverRadius: 0,
        pointBorderWidth: 5,
        pointBackgroundColor: '#109cf1',
        pointBorderColor: '#109cf1',
        pointHoverBackgroundColor: '#109cf1',
        pointHoverBorderColor: '#109cf1',
        pointHoverBorderWidth: 4,
      };
    }, 200);
  }
  get gradient(): any {
    const gradient = (this.chart.ctx as any).createLinearGradient(0, 0, 0, 240);
    gradient.addColorStop(0.0, 'rgba(16, 156, 241, 0.5)');
    gradient.addColorStop(1.0, 'rgba(16, 156, 241, 0)');
    return gradient;
  }
}
