import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { patternValidator } from './pattern-validator';
import { AuthService } from '../../shared/auth.service';
import { Router } from '@angular/router';

declare let window;

@Component({
  selector: 'pref-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loading = false;
  error: string;

  loginForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      patternValidator(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ),
    ]),
    password: new FormControl('', Validators.required),
  });

  constructor(public authService: AuthService, public router: Router) {}

  ngOnInit() {}

  submit() {
    this.loginForm.disable();
    this.loading = true;
    this.error = undefined;
    this.authService
      .login(this.loginForm.value.email, this.loginForm.value.password)
      .subscribe(
        (data) => {
          if (data.admin == true) localStorage.admin = true;
          this.router.navigate(['/']);
        },
        (error) => {
          this.loginForm.enable();
          this.loading = false;
          this.error = 'E-mail ou senha inválidos.';
          if (error.error.description != undefined)
            this.error = error.error.description;
        }
      );
  }
}
