import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moneyFull',
})
export class MoneyFullPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): string {
    if (value == undefined) {
      return 'n/d';
    }
    if (value.toString() == 'NaN') {
      return 'n/d';
    }
    let valueString = value.toFixed(0);
    if (valueString.length < 4) {
      valueString = value.toFixed(2);
    }
    return valueString
      .replace('.', '&')
      .replace('.', '.')
      .replace('.', '.')
      .replace('.', '.')
      .replace('.', '.')
      .replace('.', '.')
      .replace('&', ',')
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
}
