import { Component, OnInit } from '@angular/core';
import { BuscarAcoesService } from './buscar-acoes.service';
import { FormControl } from '@angular/forms';
import { merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ModalStartComponent } from './../../modal-start/modal-start.component';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

@Component({
  selector: 'app-buscar-acoes',
  templateUrl: './buscar-acoes.component.html',
  styleUrls: ['./buscar-acoes.component.scss'],
})
export class BuscarAcoesComponent implements OnInit {
  data = [];
  analisados = [];
  loading = true;
  count = 0;
  setores = [];
  searchField: FormControl = new FormControl();
  porteField: FormControl = new FormControl();
  setorField: FormControl = new FormControl();

  ucField: FormControl = new FormControl();
  constructor(
    public buscarAcoesService: BuscarAcoesService,
    public dialog: MatDialog
  ) { }
  ngOnInit(): void {
    if (localStorage.firstNovo == undefined) {
      let dialogRef = this.dialog.open(ModalStartComponent, {
        height: '470px',
        width: '620px',
      });
    }
    this.buscarAcoesService.find().subscribe((data: any) => {
      this.data = data.results;
      this.setores = data.results;

      this.analisados = data.analisados;
      this.loading = false;
      this.count = data.count;
    });
    merge(
      this.searchField.valueChanges,
      this.porteField.valueChanges,
      this.setorField.valueChanges,
      this.ucField.valueChanges
    )
      .pipe(debounceTime(200))
      .subscribe(() => {
        this.loading = true;
        this.data = [];
        if (this.ucField.value == true) {
          localStorage.uc = 'ativo';
        } else {
          localStorage.uc = undefined;
        }
        this.buscarAcoesService
          .find(
            this.searchField.value,
            this.setorField.value,
            this.porteField.value,
            this.ucField.value
          )
          .subscribe((data: any) => {
            this.analisados = data.analisados;

            this.setores = data.results;
            this.data = data.results;
            this.loading = false;
            this.count = data.count;
          });
      });
    if (localStorage.uc == 'ativo') {
      this.ucField.setValue(true);
    }
  }
}
