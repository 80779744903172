<div class="content" *ngIf="!loading">
  <div class="content-heading">
    <div style="display: none;" class="breadcump">
      <p _ngcontent-serverapp-c3="" class="breadcrumbs text-break">
        <span _ngcontent-serverapp-c3="">
          <a [routerLink]="['/buscar-acoes']">Uníverso de Cobertura</a>
          / </span><strong _ngcontent-serverapp-c3="">{{ data.name }}</strong>
      </p>
    </div>
    <div class="row">
      <div class="col-md-1">
        <div class="acao-logo" [ngStyle]="{
                      'background-image':
                        'url(/assets/empresas/' + data.ticker + '.png)'
                    }"></div>
      </div>
      <div class="col-md-7" style="min-height: 70px;">
        <div class="relative-mobile">
          <h3>{{ data.name }}</h3>
          <h5>{{ data.ticker }}</h5>
        </div>
      </div>
      <div class="col-md-2">
        <div class="info-header">
          <div class="info-header-title">
            {{data.setor}}

          </div>
          <div class="info-header-label">Setor</div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="info-header">
          <div class="info-header-title">
            {{data.dataUltimoBalanco | date:'dd/MM/yyyy'}}

          </div>
          <div class="info-header-label">Data Ult Balanço</div>
        </div>
      </div>
    </div>
  </div>
  <div class="content-acao">
    <div class="row">
      <div class="col-md-12">


        <div class="alert alert-info" *ngIf="tratamentoEspecial[data.ticker]">
          {{tratamentoEspecial[data.ticker]}}
        </div>
        <div class="heading-internal heading-left">
          <div class="heading-internal heading-left">
            <div _ngcontent-wln-c160="" class="heading-surface" style="
    background: #ffb600!important;
"></div>
            <h2 class="heading-content">
              INFORMAÇÕES IMPORTANTES
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="">
                <table class="table" style="text-align: center;margin-bottom: 0px;">
                  <thead>
                    <tr>
                      <th scope="col" matTooltip="Empresas que compõem o IGCX (Índice de Ações com Governança
                        Corporativa Diferenciada) são marcadas com “SIM”, indicando um sinal positivo para o
                        investidor.">Governança <span content-type="template">
                          <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                              fill="black" />
                          </svg>
                        </span></th>
                      <th
                        matTooltip="Mecanismo de proteção para o investidor minoritário. Exemplo: Tag Along de 100% significa que o acionista minoritário receberá 100% do valor da ação recebido pelo majoritário, no caso de troca de Controlador.">
                        Tag Along
                        <span content-type="template">
                          <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                              fill="black" />
                          </svg>
                        </span>
                      </th>
                      <th
                        matTooltip="Indicativo do grau de endividamento da empresa, essencial para avaliar a saúde financeira da operação. “Patrimônio Líquido Negativo” e “Alto Endividamento” são sinais de alerta.">
                        Dívida
                        <span content-type="template">
                          <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                              fill="black" />
                          </svg>
                        </span>
                      </th>
                      <th scope="col">Lucro por Ação Atual</th>
                      <th scope="col">Índice Preço/Lucro Atual</th>
                      <th scope="col">Índice Preço/Lucro do Tesouro</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ data.empreenderDinheiro.governanca }}</td>

                      <td>{{data.empreenderDinheiro.tagAlong}} - {{ data.empreenderDinheiro.tagAlongPorcentagem }}</td>

                      <td [ngClass]="{
                      dividaVerde:
                        data.empreenderDinheiro.divida.toLowerCase() ==
                          'caixa maior que a dívida' ||
                        data.empreenderDinheiro.divida.toLowerCase() ==
                          'dívida sob controle' ||
                        data.empreenderDinheiro.divida == 'Baixo Endividamento',
                      dividaVeremlho:
                        data.empreenderDinheiro.divida ==
                          'Alto Endividamento' ||
                        data.empreenderDinheiro.divida ==
                          'Patrimônio Líq. Negativo'
                    }">{{ data.empreenderDinheiro.divida }}</td>

                      <td [ngClass]="{'vermelho-text': data.cotacao.lpaAtual < 0}">R$
                        {{data.cotacao.lpaAtual | moneyFull}}</td>

                      <td [ngClass]="{'vermelho-text': data.cotacao.indicePrecoLucroAno < 0}">
                        {{(data.cotacao.indicePrecoLucroAno | number: '1.1-1')?.replace('.', ',')}} anos</td>
                      <td>9,1 anos</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card" style="margin-top: 12px;">
              <table class="table" style="text-align: center;margin-bottom: 0px;">
                <thead>
                  <tr>
                    <th style="background: #edf9ff;" scope="col">Cotação em
                      {{data.dataUltimaCotacao | date:'dd/MM/yyyy'}}</th>
                    <th class="cotacao-maxima">Cotação Máxima <span style="font-size: 10px;">(Últ 12m)</span></th>
                    <th class="cotacao-minima">Cotação Mínima <span style="font-size: 10px;">(Últ 12m)</span></th>
                    <th [matTooltip]="getTooltipPorte(data.porte) ">
                      Porte
                      <span content-type="template">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                            fill="black" />
                        </svg>
                      </span>
                    </th>
                    <th matTooltip="IBOV é o mais importante indicador do desempenho médio das cotações das
                        ações de maior capitalização negociadas na B3.">Part. IBOV <span content-type="template">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                            fill="black" />
                        </svg>
                      </span></th>
                    <th>Valor de Mercado</th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="
                            font-size: 18px;
                            margin-top: 10px;
                            background: #edf9ff;
                            font-weight: bold;
                        ">R$ {{data.cotacao.atual | moneyFull}} </td>
                    <td class="cotacao-maxima" style="
                                                    font-size: 18px;
                                                    margin-top: 10px;
                                                    font-weight: bold;
                                                ">R$ {{data.cotacao.ultimos12Meses.max | moneyFull}}</td>
                    <td class="cotacao-minima" style="
                                                                            font-size: 18px;
                                                                            margin-top: 10px;
                                                                            font-weight: bold;
                                                                        ">R$
                      {{data.cotacao.ultimos12Meses.min | moneyFull}}</td>
                    <td>{{data.porte}}</td>
                    <td>
                      {{ (data.participacao.ibovespa * 100).toFixed(2) }}%
                    </td>
                    <td>
                      R$ {{ data.valuation*100 | milionMoney }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
        <div *ngIf="data.classe == 'UNT N2' || data.classe == 'UNT'" style="margin-top: 12px;" class="alert alert-info"
          role="alert">
          Essa ação é uma UNIT, ou seja, um agrupamento de ações, compostas por uma combinação entre ON + PN.

        </div>

        <div
          style="margin-top: 82px;background: #FFF;padding-top: 13px;background: #ffffff;border: 1px solid rgba(0, 0, 0, 0.1);box-sizing: border-box;box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.05);border-radius: 5px;"
          class="heading-internal heading-left">
          <div _ngcontent-wln-c160="" class="heading-surface" style="
    height: 51px;
    background: #08e3ff!important;
"></div>
          <h1 style="font-size: 28px;">Faça sua avaliação em 3 etapas:</h1>
          <hr>
        </div>
        <!--Divisor de heading -->
        <div class="heading-internal heading-left">
          <h2 class="heading-content">
            1. ANÁLISE DO DESEMPENHO HISTÓRICO
          </h2>
        </div>

        <!--Divisor de heading -->
        <div class="proposta-card">
          <div class="heading-internal" style="margin-bottom: 0px">
            <div class="heading-surface"></div>
            <h2 class="heading-content">
              Receita Operacional Líquida (R$ mil)
              <span matTooltip="Receita Operacional Líquida = Receita bruta (-) impostos incidentes sobre
as vendas (-) descontos concedidos." content-type="template">
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                    fill="black" />
                </svg>
              </span>
            </h2>
            <div class="heading-right">
              <mat-tab-group (selectedIndexChange)="selectTab($event, 'rol')">
                <mat-tab (click)="activeTabs.rol = 'table'">
                  <ng-template mat-tab-label> Tabela </ng-template>
                </mat-tab>
                <mat-tab (click)="activeTabs.rol = 'chart'">
                  <ng-template mat-tab-label> Gráfico </ng-template>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
          <div *ngIf="activeTabs.rol == 'chart'" class="proposta-card-content">
            <app-line-chart [data]="data.rol" [title]="'Receita Operacional'"></app-line-chart>
          </div>
          <div class="proposta-card-content">
            <table class="table table-acao">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th *ngFor="let rol of data.rol | reverseOne; let i = index" scope="col">

                    {{i == (data.rol.length -1) ? (data.dataUltimoBalanco | date: 'M') + '/' : ''}}{{ rol.title |
                    yearMonth }}
                  </th>
                  <th class="dark-bg">Médias</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th class="td-left">Receita Op. Líquida (R$ mil)</th>
                  <td *ngFor="let rol of data.rol | reverseOne; let i = index" scope="col"
                    [ngClass]="{'vermelho-text': rol.value < 0}"
                    (dblclick)="toogleField('rol', data.rol.length - 1 - i, $event)">
                    R$ {{ rol.value | moneyFull }}
                  </td>
                  <td class="dark-bg">
                    -
                  </td>
                </tr>
                <tr>
                  <th class="td-left">Cresc. ROL</th>
                  <td *ngFor="let rol of data.rol | reverseOne; let i = index"
                    [ngClass]="{'vermelho-text': rol.crescimento < 0}"
                    (dblclick)="toogleField('rolCrescimento', data.rol.length - 1 - i, $event)" scope="col">
                    {{ rol.crescimento | percent }}
                  </td>
                  <td class="dark-bg" [ngClass]="{'vermelho-text':  mediaRol < 0}">{{mediaRol | percent}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <app-select-analise [control]="analiseRetrospectiva.controls.rol"
            [subtitle]="'SOBRE A RECEITA LÍQUIDA, VOCÊ DIRIA QUE ESTÁ:'"></app-select-analise>
        </div>

        <!--Divisor de heading -->
        <div class="proposta-card">
          <div class="heading-internal" style="margin-bottom: 0px">
            <div class="heading-surface"></div>
            <h2 class="heading-content">
              MARGEM EBITDA
              <span
                matTooltip="A margem EBITDA ou LAJIDA (Lucro antes de Juros, Impostos, Depreciação e Amortizações), é um indicador de lucratividade operacional do negócio. "
                content-type="template">
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                    fill="black" />
                </svg>
              </span>
            </h2>
            <div class="heading-right">
              <mat-tab-group (selectedIndexChange)="selectTab($event, 'lucroOperacional')">
                <mat-tab (click)="activeTabs.lucroOperacional = 'table'">
                  <ng-template mat-tab-label> Tabela </ng-template>
                </mat-tab>
                <mat-tab (click)="activeTabs.lucroOperacional = 'chart'">
                  <ng-template mat-tab-label> Gráfico </ng-template>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
          <div *ngIf="activeTabs.lucroOperacional == 'chart'" class="proposta-card-content">
            <app-line-chart field="margem" [data]="data.ebitda" pre="" pos="% " [title]="'Margem EBTIDA'">
            </app-line-chart>
          </div>
          <div class="proposta-card-content">
            <table class="table table-acao">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th *ngFor="let ebitda of data.ebitda | reverseOne; let i = index" scope="col">
                    {{i == (data.ebitda.length -1) ? (ebitda.title | date: 'M') + '/' : ''}}{{ ebitda.title | yearMonth
                    }}
                  </th>
                  <th class="dark-bg">Médias</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th class="td-left">Lucro Operacional (EBTIDA) R$ Mil</th>
                  <td *ngFor="let ebitda of data.ebitda | reverseOne;
                      let i = index" scope="col" (dblclick)="toogleField('ebitda', data.ebitda.length - 1 - i, $event)"
                    [ngClass]="{'vermelho-text': ebitda.value < 0}">
                    R$ {{ ebitda.value | moneyFull }}
                  </td>
                  <td class="dark-bg">-</td>
                </tr>
                <tr>
                  <th class="td-left">Cresc. EBTIDA</th>
                  <td *ngFor="
                      let ebitda of data.ebitda | reverseOne;
                      let i = index
                    " scope="col" (dblclick)="toogleField('ebitdaCrescimento', data.ebitda.length - 1 - i, $event)"
                    [ngClass]="{'vermelho-text': ebitda.crescimento < 0}">
                    {{ ebitda.crescimento | percent }}
                  </td>

                  <td class="dark-bg" [ngClass]="{'vermelho-text':  mediaEbitda < 0}">
                    {{mediaEbitda | percent}}
                  </td>
                </tr>
                <tr *ngIf="data.booleans.rol">
                  <th class="td-left">Margem EBTIDA</th>
                  <td *ngFor="
                      let ebitda of data.ebitda | reverseOne;
                      let i = index
                    " (dblclick)="toogleField('margemEbtida', data.ebitda.length - 1 - i, $event)"
                    [ngClass]="{'vermelho-text': ebitda.margem < 0}" scope="col">
                    {{ ebitda.margem | percent }}
                  </td>
                  <td class="dark-bg" [ngClass]="{'vermelho-text':  mediaMargemEbitda < 0}">
                    {{mediaMargemEbitda | percent}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <app-select-analise [control]="analiseRetrospectiva.controls.lucroOperacional"
            [subtitle]="'SOBRE A MARGEM EBITDA, VOCÊ DIRIA QUE ESTÁ:'"></app-select-analise>
        </div>
        <!--Divisor de heading -->
        <div class="proposta-card">
          <div class="heading-internal" style="margin-bottom: 0px">
            <div class="heading-surface"></div>
            <h2 class="heading-content">
              Margem Líquida (LL/ROL)
              <span
                matTooltip="Margem Líquida é o Lucro Líquido dividido pela Receita Operacional Líquida. Se a margem líquida da empresa é negativa significa que a empresa não gera lucro e sim prejuízo."
                content-type="template">
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                    fill="black" />
                </svg>
              </span>
            </h2>
            <div class="heading-right">
              <mat-tab-group (selectedIndexChange)="selectTab($event, 'lpa')">
                <mat-tab (click)="activeTabs.lpa = 'table'">
                  <ng-template mat-tab-label> Tabela </ng-template>
                </mat-tab>
                <mat-tab (click)="activeTabs.lpa = 'chart'">
                  <ng-template mat-tab-label> Gráfico </ng-template>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
          <div *ngIf="activeTabs.lpa == 'chart'" class="proposta-card-content">
            <app-line-chart [data]="data.margemLiquida" pre="" pos="%" [title]="'Margem Liquida'"></app-line-chart>
          </div>
          <div class="proposta-card-content">
            <table class="table table-acao">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th *ngFor="let lpa of data.lpa | reverseOne; let i = index" scope="col">
                    {{i == (data.lpa.length -1) ? (data.dataUltimoBalanco | date: 'M') + '/' : ''}}{{ lpa.title |
                    yearMonth }}
                  </th>

                  <th class="dark-bg">Médias</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th class="td-left">Lucro por Ação (R$)</th>
                  <td *ngFor="let lpa of data.lpa | reverseOne; let i = index"
                    (dblclick)="toogleField('lpa', data.lpa.length - 1 - i, $event)"
                    [ngClass]="{'vermelho-text': lpa.value < 0}" scope="col">
                    R$ {{ lpa.value | moneyFull }}
                  </td>
                  <td class="dark-bg">-</td>
                </tr>
                <tr>
                  <th class="td-left">Cresc. LPA</th>
                  <td *ngFor="let lpa of data.lpa | reverseOne; let i = index"
                    [ngClass]="{'vermelho-text': lpa.crescimento < 0}"
                    (dblclick)="toogleField('lpaCrescimento', data.lpa.length - 1 - i, $event)" scope="col">
                    {{ lpa.crescimento | percent }}
                  </td>
                  <td class="dark-bg" [ngClass]="{'vermelho-text':  mediaLpaCrescimento < 0}">
                    {{mediaLpaCrescimento | percent}}</td>
                </tr>
                <tr>
                  <th class="td-left">Margem Liquida</th>
                  <td *ngFor="
                      let margemLiquida of data.margemLiquida | reverseOne;
                      let i = index
                    " (dblclick)="toogleField('margemLiquida', data.margemLiquida.length - 1 - i, $event)"
                    [ngClass]="{'vermelho-text': margemLiquida.value < 0}" scope="col">
                    {{ margemLiquida.value * 100 | percent }}
                  </td>
                  <td class="dark-bg" [ngClass]="{'vermelho-text':  mediaMargemLiquida < 0}">
                    {{mediaMargemLiquida* 100 | percent }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <app-select-analise [control]="analiseRetrospectiva.controls.lpa"
            [subtitle]="'SOBRE A MARGEM LIQUIDA, VOCÊ DIRIA QUE ESTÁ:'"></app-select-analise>
        </div>
        <!--Divisor de heading -->
        <div class="proposta-card">
          <div class="heading-internal" style="margin-bottom: 0px">
            <div class="heading-surface"></div>
            <h2 class="heading-content">
              Dividendos por Ação
              <span
                matTooltip="Porcentagem do lucro pago pela empresa ao acionista, por cada ação negociada no mercado. Importante indicador para quem deseja construir uma carteira de investimentos com foco em renda passiva."
                content-type="template">
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                    fill="black" />
                </svg>
              </span>
            </h2>
            <div class="heading-right">
              <mat-tab-group (selectedIndexChange)="selectTab($event, 'dpa')">
                <mat-tab (click)="activeTabs.dpa = 'table'">
                  <ng-template mat-tab-label> Tabela </ng-template>
                </mat-tab>
                <mat-tab (click)="activeTabs.dpa = 'chart'">
                  <ng-template mat-tab-label> Gráfico </ng-template>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
          <div *ngIf="activeTabs.dpa == 'chart'" class="proposta-card-content">
            <app-line-chart [data]="data.dpa" [title]="'Dividendos por ação'"></app-line-chart>
          </div>
          <div class="proposta-card-content">
            <table class="table table-acao">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th *ngFor="let dpa of data.dpa | reverseOne; let i = index" scope="col">
                    {{i == (data.dpa.length -1) ? (data.dataUltimoBalanco | date: 'M') + '/' : ''}}{{ dpa.title |
                    yearMonth }}
                  </th>
                  <th class="dark-bg">Médias</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th class="td-left">Dividendos por Ação (R$)</th>
                  <td *ngFor="let dpa of data.dpa | reverseOne; let i = index" scope="col"
                    (dblclick)="toogleField('dpa', data.dpa.length - 1 - i, $event)"
                    [ngClass]="{'vermelho-text':  dpa.value < 0}">
                    R$ {{ dpa.value | moneyFull }}
                  </td>
                  <td class="dark-bg">
                    -
                  </td>
                </tr>
                <tr>
                  <th class="td-left">Cresc. Proventos</th>
                  <td *ngFor="let dpa of data.dpa | reverseOne; let i = index"
                    (dblclick)="toogleField('dpaCrescimento', data.dpa.length - 1 - i, $event)"
                    [ngClass]="{'vermelho-text':  dpa.crescimento < 0}" scope="col">
                    {{ dpa.crescimento | percent }}
                  </td>
                  <td class="dark-bg" [ngClass]="{'vermelho-text':  mediaDpa < 0}">
                    {{mediaDpa| percent}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <app-select-analise [control]="analiseRetrospectiva.controls.dpa"
            [subtitle]="'SOBRE O DIVIDENDOS POR AÇÃO, VOCÊ DIRIA QUE ESTÁ:'"></app-select-analise>
        </div>
        <!--Divisor de heading -->
        <div class="proposta-card">
          <div class="heading-internal" style="margin-bottom: 0px">
            <div class="heading-surface"></div>
            <h2 class="heading-content">
              Return on Equity (ROE)
              <span
                matTooltip="Retorno sobre o Patrimônio. Trata-se de um indicador que demonstra a eficiência da gestão da empresa, a partir da relação dos lucros gerados frente ao patrimônio."
                content-type="template">
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                    fill="black" />
                </svg>
              </span>
            </h2>
            <div class="heading-right">
              <mat-tab-group (selectedIndexChange)="selectTab($event, 'patrimonioLiquido')">
                <mat-tab (click)="activeTabs.patrimonioLiquido = 'table'">
                  <ng-template mat-tab-label> Tabela </ng-template>
                </mat-tab>
                <mat-tab (click)="activeTabs.patrimonioLiquido = 'chart'">
                  <ng-template mat-tab-label> Gráfico </ng-template>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
          <div *ngIf="activeTabs.patrimonioLiquido == 'chart'" class="proposta-card-content">
            <app-line-chart [data]="data.roe" pre="" pos="%" [title]="'ROE'"></app-line-chart>
          </div>
          <div class="proposta-card-content">
            <table class="table table-acao">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th *ngFor="
                      let patrimonioLiquido of data.patrimonioLiquido
                        | reverseOne; let i = index
                    " scope="col">
                    {{i == (data.patrimonioLiquido.length -1) ? (data.dataUltimoBalanco | date: 'M') + '/' : ''}}{{
                    patrimonioLiquido.title | yearMonth }}
                  </th>
                  <th class="dark-bg">Médias</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th class="td-left">Patrimônio Líquido (R$ mil)</th>
                  <td *ngFor="
                      let patrimonioLiquido of data.patrimonioLiquido
                        | reverseOne; let i = index
                    " (dblclick)="toogleField('patrimonioLiquido', data.patrimonioLiquido.length - 1 - i, $event)"
                    [ngClass]="{'vermelho-text':  patrimonioLiquido.value < 0}" scope="col">
                    R$ {{ patrimonioLiquido.value | moneyFull }}
                  </td>
                  <td class="dark-bg">
                    -
                  </td>
                </tr>
                <tr>
                  <th class="td-left">Return on Equity - ROE</th>

                  <td *ngFor="
                                      let roe of data.roe
                                        | reverseOne; let i = index
                                    " [ngClass]="{'vermelho-text':  roe.value < 0}"
                    (dblclick)="toogleField('roe', data.roe.length - 1 - i, $event)"
                    [ngClass]="{'vermelho-text':  roe.value < 0}" scope="col">
                    {{ roe.value * 100 | percent }}
                  </td>
                  <td class="dark-bg" [ngClass]="{'vermelho-text':  mediaRoeValue < 0}">{{mediaRoeValue*100 | percent}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <app-select-analise [control]="analiseRetrospectiva.controls.patrimonioLiquido"
            [subtitle]="'SOBRE O ROE, VOCÊ DIRIA QUE ESTÁ:'"></app-select-analise>
        </div>
        <!--Divisor de heading -->
        <div class="heading-internal heading-left">
          <h2 class="heading-content">
            2. ANÁLISE DOS ÍNDICES HISTÓRICOS
            <span
              matTooltip="Nesse bloco você avalia o histórico dos índices, comparando as referências médias com os valores atuais."
              content-type="template">
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                  fill="black" />
              </svg>
            </span>
          </h2>
        </div>

        <!--Divisor de heading -->
        <div class="proposta-card proposta-card-yellow">
          <div class="proposta-card-content">
            <table class="table table-acao">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th *ngFor="let cotacao of data.cotacao.meses | reverseOne; let i = index" scope="col">
                    {{i == (data.cotacao.meses.length -1) ? (data.dataUltimoBalanco | date: 'M') + '/' : ''}}{{
                    cotacao.title | yearMonth }}
                  </th>
                  <th class="td-transparent"> Médias</th>
                </tr>
              </thead>
              <tbody>
                <!--
                  <tr>
                  <th class="td-left">Cotação Maxíma (R$)
                    <span matTooltip="Maior cotação da ação no período" content-type="template">
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                  fill="black"
                />
              </svg>
            </span>
                  </th>
                  <td
                    *ngFor="let cotacao of data.cotacao.meses | reverseOne; let i = index"
                    [ngClass]="{'vermelho-text': cotacao.max < 0}"

                    (dblclick)="toogleField('cotacaoMax', data.cotacao.meses.length - 1 - i, $event)"
                    scope="col"
                  >
                    R$ {{ cotacao.max | moneyFull }}
                  </td>
                  <td>-</td>

                </tr>
                <tr>
                  <th class="td-left">Cotação Mínima (R$)
                    <span matTooltip="Menor cotação da ação no período" content-type="template">
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                  fill="black"
                />
              </svg>
            </span>
                  </th>
                  <td
                    *ngFor="let cotacao of data.cotacao.meses | reverseOne; let i = index"

                    (dblclick)="toogleField('cotacaoMin', data.cotacao.meses.length - 1 - i, $event)"
                    [ngClass]="{'vermelho-text': cotacao.min < 0}"
                    scope="col"
                  >
                    R$ {{ cotacao.min | moneyFull }}
                  </td>
                  <td>-</td>

                </tr>
                -->
                <tr class="ipl-minimo">

                  <th class="td-left">Índice Preço/Lucro Máximo
                    <span matTooltip="Maior índice do PREÇO da Ação em relação ao LUCRO por ação no período."
                      content-type="template">
                      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                          fill="black" />
                      </svg>
                    </span>
                  </th>
                  <td *ngFor="let cotacao of data.cotacao.meses | reverseOne; let i = index"
                    (dblclick)="toogleField('iplMax', data.cotacao.meses.length - 1 - i, $event)"
                    [ngClass]="{'vermelho-text': cotacao.iplMax < 0}" scope="col">
                    <span *ngIf="cotacao.iplMax">{{ (cotacao.iplMax | number : '1.1-1')?.replace('.', ',') }} Anos
                    </span>
                    <span *ngIf="!cotacao.iplMax">n/d</span>
                  </td>

                  <td class="td-transparent">
                    <span *ngIf="mediaIPLMax">{{ (mediaIPLMax | number : '1.1-1')?.replace('.', ',') }} Anos
                    </span>
                    <span *ngIf="!mediaIPLMax">n/d</span>
                  </td>

                </tr>
                <tr class="ipl-minimo">
                  <th class="td-left">Índice Preço/Lucro Mínimo
                    <span matTooltip="Menor índice do PREÇO da Ação em relação ao LUCRO por ação no período."
                      content-type="template">
                      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                          fill="black" />
                      </svg>
                    </span>
                  </th>
                  <td *ngFor="let cotacao of data.cotacao.meses | reverseOne; let i = index"
                    (dblclick)="toogleField('iplMin', data.cotacao.meses.length - 1 - i, $event)"
                    [ngClass]="{'vermelho-text': cotacao.iplMin < 0}" scope="col">
                    {{ cotacao.iplMin | number : '1.1-1' }} Anos
                  </td>
                  <td class="td-transparent">{{mediaIPLMin| number : '1.1-1' }} Anos</td>

                </tr>
                <tr class="yield-minimo">
                  <th class="td-left">Dividend Yield Máximo
                    <span
                      matTooltip="Maior índice de relação entre os dividendos distribuídos e o preço atual da ação no período."
                      content-type="template">
                      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                          fill="black" />
                      </svg>
                    </span>
                  </th>
                  <td *ngFor="let cotacao of data.cotacao.meses | reverseOne; let i = index"
                    (dblclick)="toogleField('yieldMax', data.cotacao.meses.length - 1 - i, $event)"
                    [ngClass]="{'vermelho-text': cotacao.yieldMax < 0}" scope="col">
                    {{ cotacao.yieldMax | percent }}
                  </td>
                  <td class="td-transparent">{{mediaYieldMax| percent }}</td>


                </tr>
                <tr class="yield-minimo">
                  <th class="td-left">Dividend Yield Mínimo
                    <span
                      matTooltip="Menor índice da relação entre os dividendos distribuídos e o preço atual da ação no período."
                      content-type="template">
                      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                          fill="black" />
                      </svg>
                    </span>
                  </th>
                  <td *ngFor="let cotacao of data.cotacao.meses | reverseOne; let i = index"
                    (dblclick)="toogleField('yieldMin', data.cotacao.meses.length - 1 - i, $event)"
                    [ngClass]="{'vermelho-text': cotacao.yieldMin < 0}" scope="col">
                    {{ cotacao.yieldMin | percent }}
                  </td>
                  <td class="td-transparent">{{mediaYieldMin| percent}}</td>


                </tr>
                <tr class="payout-tr">

                  <th class="td-left">Payout
                    <span
                      matTooltip="Porcentagem do lucro líquido distribuído, na forma de dividendos ou juros sobre capital próprio, aos acionistas da empresa."
                      content-type="template">
                      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                          fill="black" />
                      </svg>
                    </span>
                  </th>
                  <td *ngFor="let cotacao of data.cotacao.meses | reverseOne; let i = index"
                    (dblclick)="toogleField('payout', data.cotacao.meses.length - 1 - i, $event)"
                    [ngClass]="{'vermelho-text': cotacao.payout < 0}" scope="col">
                    {{ cotacao.payout | percent }}
                  </td>
                  <td class="td-transparent">{{mediaPayout | percent}}</td>

                </tr>

              </tbody>
            </table>
          </div>
          <div class="proposta-card-content" style="
    padding: 12px 22px;
">
            <h4 style="
    font-size: 18px;
    text-align: center;

    color: #c7882b;
    font-weight: bold;
">
              Compare as referências históricas com os dados atuais e continue sua análise:
            </h4>
            <div class="row" style="margin-bottom: 20px; text-align: center; width: 100%;">
              <div class="col-md-6">
                <div class="title" style="margin-bottom: 12px;">
                  Índice Preço/Lucro Máximo
                </div>
                <div class="row" style="margin-bottom: 20px;">
                  <div class="col-6">

                    <span class="span-text">Referência</span>
                    <span>{{referenciaIpl | number : '1.1-1'}} anos</span>
                  </div>
                  <div class="col-6">

                    <span class="span-text">Atual</span>
                    <span>{{data.cotacao.indicePrecoLucroAno | number : '1.1-1'}} anos</span>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="title" style="margin-bottom: 12px;">
                  Dividend Yield
                </div>
                <div class="row">
                  <div class="col-6">
                    <span class="span-text">Referência</span>
                    <span>{{referenciaYield | percent}}</span>

                  </div>
                  <div class="col-6">


                    <span class="span-text">Atual</span>
                    <span>{{lastYield | percent}}</span>

                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
        <!--Divisor de heading -->
        <div class="heading-internal heading-left">
          <h2 class="heading-content">
            3. CENÁRIO PROJETIVO
            <span matTooltip="Com base nos dados históricos dos últimos anos,
projete o cenário futuro esperado." content-type="template">
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                  fill="black" />
              </svg>
            </span>
          </h2>
        </div>
        <div class="proposta-card proposta-card-green">
          <div class="heading-internal" style="margin-bottom: 0px">
            <div class="heading-surface"></div>
            <h2 class="heading-content">
              Projeção de Receita

              <span matTooltip="Preencha sua expectativa para o comportamento da RECEITA
nos próximos anos, use como referência a média histórica apresentada." content-type="template">
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                    fill="black" />
                </svg>
              </span>
            </h2>
          </div>
          <div class="proposta-card-content">
            <p style="
                padding-left: 26px;
                margin-top: -24px;
                margin-bottom: 20px;
                font-family: Red Hat Display;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                /* or 157% */
                display: flex;
                align-items: center;
                color: rgba(0, 0, 0, 0.6);
              ">
            </p>
            <table class="table table-acao">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col" class="diff-col">Média de crescimento</th>
                  <th scope="col" class="diff-col">Ult. 12m <span class="other">{{data.dataUltimoBalanco | date:
                      'M/yyyy'}}</span></th>

                  <th *ngFor="let rolEstimativa of estimativaData.rol" scope="col">

                    {{ rolEstimativa.title | yearMonth }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th class="td-left">Receita Op. Líquida (R$ mil)</th>
                  <td scope="col" class="diff-col">Últ. 5 anos</td>
                  <td scope="col" class="diff-col">
                    R$ {{ prospectData.ultimaRol | moneyFull }}
                  </td>

                  <td *ngFor="let rolEstimativa of estimativaData.rol"
                    [ngClass]="{'vermelho-text': rolEstimativa.value < 0}" scope="col">
                    R$ {{ rolEstimativa.value | moneyFull }}
                  </td>
                </tr>
                <tr>
                  <th class="td-left">Cresc. ROL</th>
                  <td scope="col" class="diff-col">{{ prospectData.mediaRol | percent }}</td>
                  <td scope="col" class="diff-col">-</td>

                  <td *ngFor="let rolEstimativa of estimativaData.rol"
                    [ngClass]="{'vermelho-text': estimativasPorcentagem.controls.rol.value < 0}" scope="col">
                    {{ estimativasPorcentagem.controls.rol.value | percent }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-padding" style="padding-bottom: 10px">
            <p style="margin-top: 10px;font-size: 18px;margin-bottom: 12px;font-weight: bold;color: #000000;">Proponha
              uma projeção de crescimento: </p>

            <mat-form-field appearance="outline">
              <mat-label>Projeção (%)</mat-label>
              <input [formControl]="estimativasPorcentagem.controls.rol" matInput type="number" required />
              <span matSuffix style="font-weight: 600; font-size: 21px; color: #398e2c">%</span>
            </mat-form-field>
            <p style="
                                                        margin-top: -10px;
                                                        font-size: 14px;
                                                        margin-bottom: 16px;
                                                    " class="card-obs"><b>Atenção:</b> Em caso de dúvida, use como
              referência a média de crescimento histórico e evite colocar superior a isso.</p>
          </div>
        </div>
        <!--- Divisor de aguas-->
        <div class="proposta-card proposta-card-green">
          <div class="heading-internal" style="margin-bottom: 0px">
            <div class="heading-surface"></div>
            <h2 class="heading-content">
              Projeção dos Lucros

              <span tooltip="matTooltip"
                matTooltip="Preencha sua expectativa para o comportamento do LUCRO POR AÇÃO nos próximos anos, use como referência a média de crescimento do LPA."
                content-type="template">
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                    fill="black" />
                </svg>
              </span>
            </h2>
          </div>
          <div class="proposta-card-content">
            <p style="
                padding-left: 26px;
                margin-top: -24px;
                margin-bottom: 20px;
                font-family: Red Hat Display;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                /* or 157% */
                display: flex;
                align-items: center;
                color: rgba(0, 0, 0, 0.6);
              ">
            </p>
            <table class="table table-acao">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col" class="diff-col">Média de crescimento</th>
                  <th scope="col" class="diff-col">Ult. 12m <span class="other">{{data.dataUltimoBalanco | date:
                      'M/yyyy'}}</span></th>

                  <th *ngFor="let lpaEstimativa of estimativaData.lpa" scope="col">
                    {{ lpaEstimativa.title | yearMonth }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th class="td-left">LUCRO POR AÇÃO (R$)</th>
                  <td scope="col" class="diff-col" [ngClass]="{'vermelho-text': prospectData.mediaLpa < 0}">Últ. 5 anos

                  </td>
                  <td scope="col" class="diff-col" [ngClass]="{'vermelho-text': prospectData.ultimaLpa < 0}">
                    R$ {{ prospectData.ultimaLpa | moneyFull }}
                  </td>

                  <td *ngFor="let lpaEstimativa of estimativaData.lpa"
                    [ngClass]="{'vermelho-text': lpaEstimativa.value < 0}" scope="col">
                    R$ {{ lpaEstimativa.value | moneyFull }}
                  </td>
                </tr>
                <tr>
                  <th class="td-left">Cresc. LPA</th>
                  <td scope="col" class="diff-col">{{ prospectData.mediaLpa | percent }}</td>
                  <td scope="col" class="diff-col">-</td>

                  <td *ngFor="let lpaEstimativa of estimativaData.lpa"
                    [ngClass]="{'vermelho-text': estimativasPorcentagem.controls.lpa.value < 0}" scope="col">
                    {{ estimativasPorcentagem.controls.lpa.value | percent }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-padding" style="padding-bottom: 10px">
            <p style="margin-top: 10px;font-size: 18px;margin-bottom: 12px;font-weight: bold;color: #000000;">Proponha
              um percentual de crescimento: </p>
            <mat-form-field appearance="outline">
              <mat-label>Projeção (%)</mat-label>
              <input [formControl]="estimativasPorcentagem.controls.lpa" matInput type="number" required />
              <span matSuffix style="font-weight: 600; font-size: 21px; color: #398e2c">%</span>
            </mat-form-field>

            <p style="
    margin-top: -10px;
    font-size: 14px;
    margin-bottom: 16px;
" class="card-obs"><b>Atenção:</b> Em caso de dúvida, use como referência a média de crescimento histórico e evite
              colocar superior a isso.</p>
          </div>
        </div>
        <div class="proposta-card proposta-card-green">
          <div class="heading-internal" style="margin-bottom: 0px">
            <div class="heading-surface"></div>
            <h2 class="heading-content">
              Projeção dos Dividendos

              <span
                matTooltip="Preencha sua expectativa para o comportamento dos DIVIDENDOS nos próximos anos, use como referência a média de crescimento do Payout."
                content-type="template">
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                    fill="black" />
                </svg>
              </span>
            </h2>
          </div>
          <div class="proposta-card-content">
            <p style="
                padding-left: 26px;
                margin-top: -24px;
                margin-bottom: 20px;
                font-family: Red Hat Display;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                /* or 157% */
                display: flex;
                align-items: center;
                color: rgba(0, 0, 0, 0.6);
              ">
            </p>
            <table class="table table-acao">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col" class="diff-col">Média de crescimento</th>
                  <th scope="col" class="diff-col">Ult. 12m <span class="other"><span>{{data.dataUltimoBalanco | date:
                        'M/yyyy'}}</span></span></th>

                  <th *ngFor="let dpaEstimativa of estimativaData.dpa" scope="col">
                    {{ dpaEstimativa.title | yearMonth }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th class="td-left">Dividendos por Ação (R$)</th>
                  <td scope="col" class="diff-col">Últ. 5 anos
                  </td>
                  <td scope="col" class="diff-col" [ngClass]="{'vermelho-text': prospectData.ultimaDpa < 0}">
                    R$ {{ prospectData.ultimaDpa | moneyFull }}
                  </td>

                  <td *ngFor="let dpaEstimativa of estimativaData.dpa"
                    [ngClass]="{'vermelho-text': dpaEstimativa.value < 0}" scope="col">
                    R$ {{ dpaEstimativa.value | moneyFull }}
                  </td>
                </tr>
                <tr>
                  <th class="td-left">Payout Projetado</th>

                  <td scope="col" class="diff-col" [ngClass]="{'vermelho-text': prospectData.mediaPayout < 0}">
                    {{ prospectData.mediaPayout | percent }}</td>
                  <td scope="col" class="diff-col">-</td>


                  <td *ngFor="let dpaEstimativa of estimativaData.dpa"
                    [ngClass]="{'vermelho-text': estimativasPorcentagem.controls.payout.value < 0}" scope="col">
                    {{ estimativasPorcentagem.controls.payout.value | percent }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-padding" style="padding-bottom: 10px">
            <p style="margin-top: 10px;font-size: 18px;margin-bottom: 12px;font-weight: bold;color: #000000;">Proponha
              um percentual
              de Payout: </p>

            <mat-form-field appearance="outline">
              <mat-label>Projeção (%)</mat-label>
              <input [formControl]="estimativasPorcentagem.controls.payout" matInput type="number" required />
              <span matSuffix style="font-weight: 600; font-size: 21px; color: #398e2c">%</span>
            </mat-form-field>
            <p style="
                  margin-top: -10px;
                  font-size: 14px;
                  margin-bottom: 16px;
              " class="card-obs"><b>Atenção:</b> Em caso de dúvida, use como referência a média de crescimento
              histórico e evite colocar superior a isso.</p>
          </div>
        </div>
        <div class="proposta-card proposta-card-green-bg">
          <div class="heading-internal" style="margin-bottom: 0px">
            <div class="heading-surface"></div>
            <h2 class="heading-content">Projeção de Cotação</h2>
          </div>
          <div class="proposta-card-content">
            <table class="table table-acao">
              <thead>
                <tr>
                  <th scope="col"></th>

                  <th *ngFor="let cotacaoEstimativa of estimativaData.cotacao" scope="col">
                    {{ cotacaoEstimativa.title | yearMonth }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th class="td-left">
                    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <ellipse cx="14.0323" cy="14.3439" rx="14.0323" ry="14.3441"
                        transform="rotate(180 14.0323 14.3439)" fill="#398E2C" fill-opacity="0.2" />
                      <path
                        d="M12.1342 13.6079V20.0882C12.1342 20.3601 12.3473 20.5805 12.61 20.5805H14.8303C15.0931 20.5805 15.3061 20.3601 15.3061 20.0882V13.6079H17.1323C17.9801 13.6079 18.4046 12.5472 17.8052 11.9268L14.3931 8.39585C14.0214 8.01128 13.4189 8.01128 13.0473 8.39585L9.63522 11.9268C9.03578 12.5472 9.46033 13.6079 10.3081 13.6079H12.1342Z"
                        fill="#398E2C" />
                    </svg>
                    Cotáção Máx
                  </th>

                  <td *ngFor="let cotacaoEstimativa of estimativaData.cotacao" scope="col">
                    R$ {{ cotacaoEstimativa.max | moneyFull }}
                  </td>
                </tr>
                <tr>
                  <th class="td-left">
                    <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <ellipse cx="14.0323" cy="15.0321" rx="14.0323" ry="14.3441" fill="#A52800" fill-opacity="0.2" />
                      <path
                        d="M15.9303 15.7681L15.9303 9.28779C15.9303 9.01589 15.7173 8.79544 15.4546 8.79544L13.2342 8.79544C12.9715 8.79544 12.7584 9.01589 12.7584 9.28779L12.7584 15.7681L10.9323 15.7681C10.0845 15.7681 9.65994 16.8288 10.2594 17.4491L13.6715 20.9801C14.0431 21.3647 14.6456 21.3647 15.0172 20.9801L18.4294 17.4491C19.0288 16.8288 18.6042 15.7681 17.7565 15.7681L15.9303 15.7681Z"
                        fill="#D42126" />
                    </svg>
                    Cotação Mín
                  </th>

                  <td *ngFor="let cotacaoEstimativa of estimativaData.cotacao" scope="col">
                    R$ {{ cotacaoEstimativa.min | moneyFull }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-padding" style="
              padding-bottom: 20px;
              font-family: Red Hat Display;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 22px;
              align-items: center;

              color: #000000;
            ">
           <b>Atenção:</b> O resultado apresentado reflete as premissas adotas pelo investidor nos blocos anteriores. A
            Empreender
            Dinheiro não se
            responsabiliza pelas decisões tomadas com base nos resultados. O método é construído para fins educacionais,
            os dados
            são extraídos da B3 via Economática.
          </div>
        </div>

        <button *ngIf="!finalizado" [disabled]="!analiseRetrospectiva.valid" (click)="finaliza()"
          class="flat-finaliza-button" mat-flat-button color="accent">
          <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M19.5 3.25C10.53 3.25 3.25 10.53 3.25 19.5C3.25 28.47 10.53 35.75 19.5 35.75C28.47 35.75 35.75 28.47 35.75 19.5C35.75 10.53 28.47 3.25 19.5 3.25ZM19.5 32.5C12.3337 32.5 6.5 26.6663 6.5 19.5C6.5 12.3337 12.3337 6.5 19.5 6.5C26.6663 6.5 32.5 12.3337 32.5 19.5C32.5 26.6663 26.6663 32.5 19.5 32.5ZM16.25 23.0262L26.9587 12.3175L29.25 14.625L16.25 27.625L9.75 21.125L12.0413 18.8337L16.25 23.0262Z"
              fill="white" />
          </svg>
          Finalizar Análise
        </button>
        <div *ngIf="!analiseRetrospectiva.valid" style="margin-top: 12px;" class="alert alert-dark" role="alert">
          <svg style="margin-right: 20px;" width="24" height="24" viewBox="0 0 24 24" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M10.5 2.75C10.5 1.92 11.17 1.25 12 1.25C12.83 1.25 13.5 1.92 13.5 2.75V3.92C16.64 4.6 19 7.4 19 10.75V16.75L21 18.75V19.75H3V18.75L5 16.75V10.75C5 7.4 7.36 4.6 10.5 3.92V2.75ZM12 5.75C14.76 5.75 17 7.99 17 10.75V17.75H7V10.75C7 7.99 9.24 5.75 12 5.75ZM10.01 20.76C10.01 21.86 10.9 22.75 12 22.75C13.1 22.75 13.99 21.86 13.99 20.76H10.01ZM13 7.75V11.75H11V7.75H13ZM13 15.75V13.75H11V15.75H13Z"
              fill="black" fill-opacity="0.54" />
          </svg>

          Ainda existem campos a preencher, <a style="color: red; font-weight: bold; text-transform: uppercase;"
            (click)="scrollTop()">clique aqui</a> para revisar.
        </div>
        <div *ngIf="finalizado" class="proposta-card proposta-card-final">
          <div class="heading-internal" style="margin-bottom: 0px">
            <div class="heading-surface"></div>
            <h2 class="heading-content" style="color: #109cf1;">
              <svg width="51" height="44" viewBox="0 0 51 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M21.25 36.6668H29.75V7.3335H21.25V36.6668ZM8.5 36.6668H17V22.0002H8.5V36.6668ZM34 36.6668V16.5002H42.5V36.6668H34Z"
                  fill="#109CF1" />
              </svg>
              Resultado da Avaliação
            </h2>
            <div style="float: right; margin-right: 20px; max-width: 240px;">
              <button mat-stroked-button (click)="print()">Imprimir</button><button mat-stroked-button
                (click)="refazer()">Refazer a Análise</button>
            </div>
          </div>
          <div class="proposta-card-content" style="padding: 26px; padding-top: 0px">
            <div class="info-list">
              <div class="info-item">
                <div class="info-item-title">Empresa</div>
                <div>{{ data.name }}</div>
              </div>
              <div class="info-item">
                <div class="info-item-title">Data da análise</div>
                <div>{{ analise.date | date:'dd/MM/yyyy HH:mm' }}</div>
              </div>
              <div class="info-item">
                <div class="info-item-title">Cotação em {{data.dataUltimaCotacao | date:'dd/MM/yyyy'}}</div>
                <div style="font-weight: bold">
                  R${{ data.cotacao.atual | moneyFull }}
                </div>
              </div>
              <div class="info-item" style="
    border-bottom: 0px;
">
                <div class="info-item-title">Rentabilidade</div>
                <div
                  style="font-weight: bold;font-family: Red Hat Display;font-style: bold;font-size: 16px;line-height: 21px;margin-top: 12px;line-height: 42px!important;text-transform: uppercase;">
                  Ganho médio anual potencial
                  <span
                    matTooltip="Considera rentabilidade da valorização da cotação e dos proventos distribuídos para o período projetivo."
                    content-type="template">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                        fill="black" />
                    </svg>
                  </span>
                  <div style="float: right; font-size: 26px">{{analise.ganhoPotencial | percent}}</div>
                </div>
              </div>
            </div>
            <div class="row" style="margin-top: 20px; margin-bottom: 20px; width: 100%;">
              <div class="col-sm-4">
                <div class="card card-green">
                  <div class="card-body">
                    <h5 class="card-title">Comprar ações</h5>
                    <div class="row">
                      <div class="col-6">
                        <div class="card-value">
                          R$ {{analise.comprarAcoes.min | moneyFull }}
                        </div>
                        <div class="card-label">
                          Min
                          <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M4.13633 4.77243L4.13633 1.0027C4.13633 0.844532 4.0124 0.71629 3.85956 0.71629L2.56795 0.716289C2.4151 0.716289 2.29117 0.844532 2.29117 1.0027L2.29117 4.77243L1.22885 4.77243C0.735681 4.77243 0.488706 5.38945 0.837418 5.75033L2.82232 7.80438C3.03851 8.0281 3.38899 8.0281 3.60515 7.80438L5.59006 5.75033C5.93877 5.38947 5.6918 4.77243 5.19863 4.77243L4.13633 4.77243Z"
                              fill="#D42126" />
                          </svg>


                        </div>
                      </div>
                      <div class="col-6">
                        <div class="card-value">
                          R$ {{analise.comprarAcoes.max | moneyFull }}
                        </div>
                        <div class="card-label">
                          Max
                          <svg style='
    transform: rotate(180deg);' width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M4.13633 4.77243L4.13633 1.0027C4.13633 0.844532 4.0124 0.71629 3.85956 0.71629L2.56795 0.716289C2.4151 0.716289 2.29117 0.844532 2.29117 1.0027L2.29117 4.77243L1.22885 4.77243C0.735681 4.77243 0.488706 5.38945 0.837418 5.75033L2.82232 7.80438C3.03851 8.0281 3.38899 8.0281 3.60515 7.80438L5.59006 5.75033C5.93877 5.38947 5.6918 4.77243 5.19863 4.77243L4.13633 4.77243Z"
                              fill="#398E2C" />
                          </svg>


                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="card card-yellow">
                  <div class="card-body">
                    <h5 class="card-title">Posição Neutra</h5>

                    <div class="row">
                      <div class="col-6">
                        <div class="card-value">
                          R$ {{analise.posicaoNeutra.min | moneyFull }}

                        </div>
                        <div class="card-label">
                          Min
                          <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M4.13633 4.77243L4.13633 1.0027C4.13633 0.844532 4.0124 0.71629 3.85956 0.71629L2.56795 0.716289C2.4151 0.716289 2.29117 0.844532 2.29117 1.0027L2.29117 4.77243L1.22885 4.77243C0.735681 4.77243 0.488706 5.38945 0.837418 5.75033L2.82232 7.80438C3.03851 8.0281 3.38899 8.0281 3.60515 7.80438L5.59006 5.75033C5.93877 5.38947 5.6918 4.77243 5.19863 4.77243L4.13633 4.77243Z"
                              fill="#D42126" />
                          </svg>


                        </div>
                      </div>
                      <div class="col-6">
                        <div class="card-value">
                          R$ {{analise.posicaoNeutra.max | moneyFull }}
                        </div>
                        <div class="card-label">
                          Max
                          <svg style='
    transform: rotate(180deg);' width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M4.13633 4.77243L4.13633 1.0027C4.13633 0.844532 4.0124 0.71629 3.85956 0.71629L2.56795 0.716289C2.4151 0.716289 2.29117 0.844532 2.29117 1.0027L2.29117 4.77243L1.22885 4.77243C0.735681 4.77243 0.488706 5.38945 0.837418 5.75033L2.82232 7.80438C3.03851 8.0281 3.38899 8.0281 3.60515 7.80438L5.59006 5.75033C5.93877 5.38947 5.6918 4.77243 5.19863 4.77243L4.13633 4.77243Z"
                              fill="#398E2C" />
                          </svg>


                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="card">
                  <div class="card-body card-red">
                    <h5 class="card-title">Vender Ações</h5>


                    <div class="row">
                      <div class="col-6">
                        <div class="card-value">
                          R$ {{analise.venderAcoes.min | moneyFull }}
                        </div>
                        <div class="card-label">
                          Min
                          <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M4.13633 4.77243L4.13633 1.0027C4.13633 0.844532 4.0124 0.71629 3.85956 0.71629L2.56795 0.716289C2.4151 0.716289 2.29117 0.844532 2.29117 1.0027L2.29117 4.77243L1.22885 4.77243C0.735681 4.77243 0.488706 5.38945 0.837418 5.75033L2.82232 7.80438C3.03851 8.0281 3.38899 8.0281 3.60515 7.80438L5.59006 5.75033C5.93877 5.38947 5.6918 4.77243 5.19863 4.77243L4.13633 4.77243Z"
                              fill="#D42126" />
                          </svg>


                        </div>
                      </div>
                      <div class="col-6">
                        <div class="card-value">
                          R$ {{analise.venderAcoes.max | moneyFull }}
                        </div>
                        <div class="card-label">
                          Max
                          <svg style='
    transform: rotate(180deg);' width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M4.13633 4.77243L4.13633 1.0027C4.13633 0.844532 4.0124 0.71629 3.85956 0.71629L2.56795 0.716289C2.4151 0.716289 2.29117 0.844532 2.29117 1.0027L2.29117 4.77243L1.22885 4.77243C0.735681 4.77243 0.488706 5.38945 0.837418 5.75033L2.82232 7.80438C3.03851 8.0281 3.38899 8.0281 3.60515 7.80438L5.59006 5.75033C5.93877 5.38947 5.6918 4.77243 5.19863 4.77243L4.13633 4.77243Z"
                              fill="#398E2C" />
                          </svg>


                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="info-list info-list-personalizado">
              <div class="info-item">
                <div
                  matTooltip="Entenda como um índice Estatístico. Significa que de acordo com as premissas adotadas, o espaço para subir é “x” vezes maior do que a probabilidade de queda."
                  style="font-weight: bold;font-family: Red Hat Display;font-style: bold;font-size: 18px;line-height: 42px;text-transform: uppercase;">
                  ÍNDICE DE CONFIRMAÇÃO
                  <span content-type="template">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                        fill="black" />
                    </svg>
                  </span>
                  <div style="float: right; font-size: 26px;color: #109CF1;"
                    [ngClass]="{'color-green': analise.indiceED > 3, 'color-blue': analise.indiceED > 2, 'color-red': analise.indiceED <= 2}">
                    {{analise.indiceED | number: '1.0-2'}}</div>
                </div>
              </div>

              <div class="info-item">
                <div
                  style="font-weight: bold;font-family: Red Hat Display;font-style: bold;font-size: 18px;line-height: 21px;margin-top: 12px;line-height: 42px;text-transform: uppercase;">
                  Resultado de acordo com premissas adotadas:
                  <div
                    [ngClass]="{greenAction: analise.action.toLocaleLowerCase() == 'comprar ações', yellowAction: analise.action == 'Posição Neutra', redAction: analise.action == 'Vender Ações'}"
                    style="float: right; font-size: 26px">{{analise.action}}</div>
                </div>
              </div>
            </div>
          </div>
          <div style="padding: 25px; display: flex;">
            <div style="flex: 1;">Gostaria de analisar outra empresa?</div>
            <button mat-stroked-button routerLink="/buscar-acoes" style="margin-top: -5px;margin-left: 20px;">
              Voltar ao UC
            </button>
          </div>
        </div>

      </div>
      <div style="padding-left: 22px; padding-top: 22px; width: 100%;">
        <h3 style="
                    color: #000;
                    font-weight: bold;
                    font-size: 20px;
                    padding-left: 22px;
                    border-left: 4px solid #FF9800;
                ">Empresas que você pode gostar:</h3>
        <div class="row" style="width: 100%;">

          <div *ngFor="let acao of data.suggestions" class="col-md-3"
            (click)="refreshLink(['/acao', acao.ticker].join('/'))" style="cursor: pointer">
            <app-item-action [acao]="acao" [analisados]="[]">
            </app-item-action>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="loading" class="loading">
    <mat-spinner [color]="'accent'" diameter="40" mode="indeterminate">
    </mat-spinner>
  </div>
</div>
