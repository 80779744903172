import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeOfChart',
})
export class TypeOfChartPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    if (value == 'rol') {
      return 'Receita Op. Líquida ';
    }
    if (value == 'ebitda') {
      return ' Lucro Operacional';
    }
    if (value == 'lpa') {
      return ' Lucro por Ação';
    }
    if (value == 'dpa') {
      return ' Dividendos por Ação';
    }
    if (value == 'patrimonioLiquido') {
      return 'Patrimônio Líquido';
    }
    return '-';
  }
}
