import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthFolderService } from 'src/app/callback-folder/auth-folder.service';

@Injectable({
  providedIn: 'root',
})
export class AcaoService {
  ignoreFilter$ = new ReplaySubject();
  ignoreFilter = [];
  ignoreFields(name: string, title: string) {
    this.ignoreFilter.push({ name, title });
    this.ignoreFilter$.next(this.ignoreFields);
  }
  constructor(public httpClient: HttpClient, public oidcSecurityService: OidcSecurityService, public authFolderService: AuthFolderService,
  ) {
  }
  get token() {
    if (this.authFolderService.authenticated()) {
      return this.authFolderService.getAccess();
    }
    return this.oidcSecurityService.getAccessToken();
  }
  findOne(ticker: string) {
    return this.httpClient
      .get(environment.url + 'main/indexer/action/' + ticker, {
        headers: {
          Authorization: 'Bearer ' + this.token,
        },
      })
      .pipe(
        map((item) => {
          return this.mapperOne(item);
        })
      );
  }

  mapperOne(acao: any) {
    acao.booleans = {};
    // Caso seja bancos
    if (acao.formatoBalanco == "Banco Bra") {
      acao.rol = acao.rif;
      acao.ebitda = acao.lucroOperacional;
    }
    if (acao.formatoBalanco == "Insur Bra") {
      acao.rol = acao.rop;
      acao.ebitda = acao.lucroOperacional;
    }
    try {
      acao.booleans.ebitda = acao.ebitda[0]?.value != null;
      acao.booleans.rol = acao.rol[0]?.value != null;
      acao.booleans.dpa = acao.dpa[0]?.value != null;
      acao.booleans.lpa = acao.lpa[0]?.value != null;
      acao.booleans.margemLiquida = acao.margemLiquida[0]?.value != null;
    } catch (error) { }
    acao.dataUltimoBalanco = acao.dataUltimoBalanco.replace('.000Z', '');

    acao.dataUltimaCotacao = acao.dataUltimaCotacao.replace('.000Z', '');

    acao.ebitda = acao.ebitda.map((item, i) => {
      item.margem = (item.value / acao.rol[i].value) * 100;
      return item;
    });

    acao.booleans.ebitda = acao.ebitda[0]?.value != null;
    acao.cotacao.meses = acao.cotacao.meses
      .map((item, i) => {
        let lpa = acao.lpa[i];
        if (lpa == undefined) {
          return item;
        }
       
        item.iplMax =
          item.max /
          lpa.value /
          (acao.cotacao.atual /
            acao.cotacao.lpaAtual /
            acao.cotacao.indicePrecoLucroAno);
        

        item.iplMin =
          item.min /
          lpa.value /
          (acao.cotacao.atual /
            acao.cotacao.lpaAtual /
            acao.cotacao.indicePrecoLucroAno);
        return item;
      })
      .map((item, i) => {
        let dpa = acao.dpa[i];
        if (dpa == undefined) {
          return item;
        }
    
        item.yieldMax =
          ((dpa.value *
            (acao.cotacao.atual /
              acao.cotacao.lpaAtual /
              acao.cotacao.indicePrecoLucroAno)) /
            item.min) *
          100;
        item.yieldMin =
          ((dpa.value *
            (acao.cotacao.atual /
              acao.cotacao.lpaAtual /
              acao.cotacao.indicePrecoLucroAno)) /
            item.max) *
          100;
        return item;
      })
      .map((item, i) => {
        let dpa = acao.dpa[i];
        let lpa = acao.lpa[i];
        if (dpa == undefined) {
          item.payout = undefined;
          return item;
        }
        if (lpa == undefined) {
          item.payout = undefined;

          return item;
        }
        item.payout = (dpa.value / lpa.value) * 100;
        return item;
      })
      .filter((item, i) => i < 5);
    //acao.cotacao.meses = acao.cotacao.meses.map((item, i) => {
    //  item.iplMax =
    //    item.iplMax /
    //    (acao.cotacao.atual /
    //      acao.lpa[0].value /
    //      acao.cotacao.indicePrecoLucroAno);
    //  item.iplMin =
    //    item.iplMin /
    //    (acao.cotacao.atual /
    //      acao.lpa[0].value /
    //      acao.cotacao.indicePrecoLucroAno);
    //  return item;
    //});

    //
    return acao;
  }
  saveAnalise(
    data: any,
    analise: any,
    analiseRetrospectiva: any,
    estimativasPorcentagem: any,
    ignoreFields: any,
  ) {
    return this.httpClient.post(
      environment.url + 'main/indexer/comparativo/analise',
      {
        data,
        analise,
        analiseRetrospectiva,
        estimativasPorcentagem,
        ignoreFields,
      },
      {
        headers: {
          Authorization: 'Bearer ' + this.token,
        },
      }
    );
  }
  excluiAnalise(
    ticker: any,) {
    return this.httpClient.delete(
      environment.url + 'main/indexer/comparativo/analise/' + ticker,
      {
        headers: {
          Authorization: 'Bearer ' + this.token,
        },
      }
    );
  }
}
