import { Component, OnInit } from '@angular/core';
import { ComparativoService } from './comparativo.service';
import { Acao } from './acao';

@Component({
  selector: 'app-comparativo',
  templateUrl: './comparativo.component.html',
  styleUrls: ['./comparativo.component.scss'],
})
export class ComparativoComponent implements OnInit {
  acoes: Array<Acao> = [];
  loading: boolean = true;
  graficos: any = [
    {
      type: 'lpa',
    },
  ];
  constructor(public comparativoService: ComparativoService) {}

  ngOnInit(): void {
    this.loading = true;
    this.comparativoService.listAll().subscribe((data: any) => {
      this.loading = false;

      this.acoes = data.map((action: any) => {
        return new Acao(action);
      });
      
    });
  }
  get selectedCount() {
    return this.acoes.filter((acao) => {
      return acao.active == true;
    }).length;
  }
  setType(item, type) {
    setTimeout(() => {
      
      item.type = type;
      
    }, 100);
  }
  toogleField(event) {
   
    if (event.toElement.style.background == 'rgb(213, 255, 214)')
      return (event.toElement.style.background = 'none');
    event.toElement.style.background = 'rgb(213, 255, 214)';
  }
}
