<div *ngIf="code==undefined">
    <div class="login-main">
        <div class="vertical-align">
            <img src="/assets/images/logotipo.png" style="max-width: 240px; margin: 20px auto; display: block"
                alt="Empreender Dinheiro" />
            <div class="row center">
                <div class="col-md-12 col-sm-12 vertical-align">
                    <div class="login-container">
                        <div class="loading">
                            <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>
                        </div>

                        <h5><b> Quer recuperar sua senha?</b>.</h5>
                        <p>Por favor, digite seu e-mail.</p>

                        <form (submit)="submit()" [formGroup]="recoveryEmail">
                            <mat-form-field appearance="outline">
                                <mat-label>Email</mat-label>
                                <input autocomplete="email" autofocus id="emailInput" formControlName="email" matInput
                                    placeholder="exemplo: andre@empreenderdinheiro.com.br" />
                                <div matSuffix>
                                    <i class="icon-user"></i>
                                </div>
                                <mat-hint class="hint-error">
                                    <div *ngIf="
                        recoveryEmail.controls['email'].errors &&
                        !recoveryEmail.controls['email'].pristine
                      " [hidden]="!recoveryEmail.controls['email'].errors.required">
                                        Por favor preencha o campo de e-mail.
                                    </div>
                                    <div *ngIf="
                        recoveryEmail.controls['email'].errors &&
                        !recoveryEmail.controls['email'].pristine &&
                        !recoveryEmail.controls['email'].errors.required
                      " [hidden]="!recoveryEmail.controls['email'].errors.patternInvalid">
                                        Este e-mail é invalido.
                                    </div>
                                </mat-hint>
                            </mat-form-field>

                            <div *ngIf="error" style="
                    padding: 10px 12px;
                    margin-bottom: 10px;
                    border: 1px #c300004a solid;
                    border-radius: 5px;
                    background: rgba(255, 0, 0, 0.05);
                    color: #c30000;
                  ">
                                {{ error }}
                            </div>
                            <div style="margin-top: 10px">
                                <button [disabled]="!recoveryEmail.valid || loading" type="submit" [disabled]="loading"
                                    mat-raised-button style="float: right" color="primary">
                                    Continuar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<div *ngIf="code!=undefined">
    <div class="login-main">
        <div class="vertical-align">
            <img src="/assets/images/logotipo.png" style="max-width: 240px; margin: 20px auto; display: block"
                alt="Empreender Dinheiro" />
            <div class="row center">
                <div class="col-md-12 col-sm-12 vertical-align">
                    <div class="login-container">
                        <div class="loading">
                            <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>
                        </div>

                        <h5><b> Redefina sua senha</b>.</h5>
                        <p>Digite uma nova senha.</p>

                        <form (submit)="submitRecoveryPassword()" [formGroup]="recoveryPassword">
                            <mat-form-field appearance="outline">
                                <mat-label>Senha</mat-label>
                                <input autocomplete="password" autofocus id="passwordInput" formControlName="password"
                                    matInput placeholder="Digite uma senha" />
                                <div matSuffix>
                                    <i class="icon-user"></i>
                                </div>
                                <mat-hint class="hint-error">
                                    <div *ngIf="
                        recoveryPassword.controls['password'].errors &&
                        !recoveryPassword.controls['password'].pristine
                      " [hidden]="!recoveryPassword.controls['password'].errors.required">
                                        Digite uma nova senha.
                                    </div>
                                </mat-hint>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Cofirme sua senha</mat-label>
                                <input autocomplete="password" autofocus id="passwordInput"
                                    formControlName="passwordConfirmation" matInput placeholder="Confirme sua senha" />
                                <div matSuffix>
                                    <i class="icon-user"></i>
                                </div>
                                <mat-hint class="hint-error">
                                    <div *ngIf="
                                                    recoveryPassword.controls['passwordConfirmation'].errors &&
                                                    !recoveryPassword.controls['passwordConfirmation'].pristine
                                                  "
                                        [hidden]="!recoveryPassword.controls['passwordConfirmation'].errors.required">
                                        Digite uma nova senha.
                                    </div>
                                </mat-hint>
                            </mat-form-field>

                            <div *ngIf="error" style="
                    padding: 10px 12px;
                    margin-bottom: 10px;
                    border: 1px #c300004a solid;
                    border-radius: 5px;
                    background: rgba(255, 0, 0, 0.05);
                    color: #c30000;
                  ">
                                {{ error }}
                            </div>
                            <div *ngIf="recoveryPassword.errors?.notSame" style="
                                                padding: 10px 12px;
                                                margin-bottom: 10px;
                                                border: 1px #c300004a solid;
                                                border-radius: 5px;
                                                background: rgba(255, 0, 0, 0.05);
                                                color: #c30000;
                                              ">
                                Confirmação de senha incorreta.
                            </div>
                            <div style="margin-top: 10px">
                                <button [disabled]="!recoveryPassword.valid || loading" type="submit"
                                    [disabled]="loading" mat-raised-button style="float: right" color="primary">
                                    Continuar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>