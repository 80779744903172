import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(public httpClient: HttpClient) { }
  create(usuario: { fullName: String; email: String; password: String, selectusActive: string, origin: string }) {
    return this.httpClient.post(environment.url + 'users/create', {
      fullName: usuario.fullName,
      email: usuario.email,
      password: usuario.password,
      origin: usuario.origin,
      selectusActive: usuario.selectusActive,
    });
  }
}
