<div class="card-padding">
  <h5 class="card-title" style="text-transform: uppercase">{{ subtitle }}</h5>
  <mat-chip-list aria-label="Fish selection">
    <mat-chip
      style="
        font-size: 18px;
        background-color: #f3faff;
        border: 1px solid #109cf1;
        color: #109cf1;
        padding: 21px;
        border-radius: 40px;
      "
      color="accent"
      (click)="control.setValue(1)"
      [selected]="control.value == 1"
      >SUBINDO
    </mat-chip>
    <mat-chip
      style="
        font-size: 18px;
        background-color: #f3faff;
        border: 1px solid #109cf1;
        color: #109cf1;
        padding: 21px;
        border-radius: 40px;
      "
      color="accent"
      (click)="control.setValue(2)"
      [selected]="control.value == 2"
      >CAINDO
    </mat-chip>
    <mat-chip
      style="
        font-size: 18px;
        background-color: #f3faff;
        border: 1px solid #109cf1;
        color: #109cf1;
        padding: 21px;
        border-radius: 40px;
      "
      color="accent"
      (click)="control.setValue(3)"
      [selected]="control.value == 3"
      >ESTÁVEL
    </mat-chip>
    <mat-chip
      style="
        font-size: 18px;
        background-color: #f3faff;
        border: 1px solid #109cf1;
        color: #109cf1;
        padding: 21px;
        border-radius: 40px;
      "
      color="accent"
      (click)="control.setValue(4)"
      [selected]="control.value == 4"
      >INSTÁVEL
    </mat-chip>
    <mat-chip
      style="
        font-size: 18px;
        background-color: #f3faff;
        border: 1px solid #109cf1;
        color: #109cf1;
        padding: 21px;
        border-radius: 40px;
      "
      color="accent"
      (click)="control.setValue(5)"
      [selected]="control.value == 5"
      >FORTE CRESCIMENTO
    </mat-chip>
    <mat-chip
      style="
        font-size: 18px;
        background-color: #f3faff;
        border: 1px solid #109cf1;
        color: #109cf1;
        padding: 21px;
        border-radius: 40px;
      "
      color="accent"
      (click)="control.setValue(6)"
      [selected]="control.value == 6"
      >FORTE QUEDA
    </mat-chip>
  </mat-chip-list>
</div>
